.navigation {
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.logo {
  width: 75px;
  padding: 25px;
}

.logo__fill g {
  fill: lightgreen;
}

.logo__fill g:hover {
  fill: rgb(190, 235, 190);
}

.nav-links-container {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.nav-link {
  font-size: 20px;
  padding: 10px 15px;
}