.main-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.hero-container {
  width: 50%;
  padding: 25px;
  margin-left: 50px;
}