body {
  color: antiquewhite;
  line-height: 25px;
  background-color: #363636;
}

/* unvisited link */
a:link {
  color: lightgreen;
}

/* visited link */
a:visited {
  color: lightgreen;
}

/* mouse over link */
a:hover {
  color: rgb(190, 235, 190);
}

/* selected link */
a:active {
  color: lightgreen;
}

.main-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.project-container {
  width: 50%;
  padding: 25px;
  margin-left: 50px;
}